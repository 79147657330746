$primary:   #2A4DAA;
$secondary: #DAE7FE;
$success:   #2AAA5D;
$info:      #E7EDF9;
$warning:   #FBEB06;
$danger:    #AA2A2A;

$font-family-base: myriad-pro;

.btn-success {
  color: white !important;
}

.btn-outline-success:is(:hover, :focus-visible) {
  color: white !important;
}

$enable-negative-margins: true;

$accordion-button-active-bg: $secondary;
$accordion-button-bg: $secondary;
$accordion-button-active-color: $primary;
$accordion-button-color: $primary;

.accordion-button {
  &:hover {
    z-index: 0 !important;
  }
}

@import '~bootstrap/scss/bootstrap.scss';
