@import '~react-toastify/dist/ReactToastify.css';

:root {
  --toastify-color-info: var(--bs-secondary);
  --toastify-text-color-info: var(--bs-primary);

  --toastify-color-success: var(--bs-success);
  --toastify-text-color-success: var(--bs-light);

  --toastify-color-warning: var(--bs-warning);
  --toastify-text-color-warning: var(--bs-primary);

  --toastify-color-error: var(--bs-danger);
  --toastify-text-color-error: var(--bs-light);
}

.Toastify__toast-container {
  bottom: 3rem;
}
